import h from "../core/createElement.js";


export default function Btn(props = {}, children) {

    const colors = ['success', 'warning', 'danger', 'zen', 'gray', 'purple'],
        sizes = ['sm', 'md'];

    let classes = `btn ${props.class || ''}`;

    props.color && (classes += ` ${colors.find(el => el === props.color) || ''} `);
    props.size && (classes += ` ${sizes.find(el => el === props.size) || ''} `);


    const btn = (
        <button class={classes} onClick={props.onClick || false} type={props.type || false}>
            <span>{props.title || ''}</span>
            {children}
        </button>
    );

    const loading = {
        show: () => btn.classList.add('loading'),
        hide: () => btn.classList.remove('loading')
    };

    props.loading && props.loading(loading)

    return btn;
}