import h from '../core/createElement.js';
import Btn from './button.js';


export const drop_down_out_click_dissmiss = () => {
    document.body.addEventListener("click", e => {
        if (e.target.closest('.dropdown.show')) return;
        dissmiss_all();
    })
};

const dissmiss_all = () => document.querySelectorAll('.dropdown.show').forEach(e => e.classList.remove('show'));

export default function DropDown(props = {}, children) {
    const handle_click = (e) => {
        if (e.target.classList.contains('hider')) {
            drop_down.classList.remove('show');
        };
    };

    const toggle = (e) => {
        const dd = e.target.closest('.dropdown'), is_open = dd.matches('.dropdown.show');
        dissmiss_all();
        drop_down.classList[is_open ? 'remove' : 'add']('show');
    };

    const drop_down = (
        <section class={"dropdown " + (props.left ? 'left ' : '') + (props.class || '')} onClick={e => handle_click(e)}>

            <Btn color={props.color || ''} onClick={e => toggle(e)}>{props.btn || ''}</Btn>

            <div class="content">
                {children}
            </div>
        </section>
    );

    return drop_down;
};