import _ from "lodash";
import { create_v_el } from "../core/createElement.js";
import { diff } from "../core/diff.js";
import { addZero, childList_observer, dateDiff, getElapsedTime, onInit, run_or_stop_signs } from "../utils/utils.js";
import Marquee_v from "./marquee.js";

let current_table;
const priorities = ['high', 'normal', 'low', 'awaiting', 'missout'];

export default function OsPendingTable({ 'is-null': is_null, data, shared = {} }, children) {

    onInit(() => {
        childList_observer(current_table.element, ms => run_or_stop_signs(current_table.element));
    });

    shared.os_pending_table_data_updater = (data) => {
        is_null && is_null(!data);

        const t = data ? data.sort((a, b) => a.Abertura.localeCompare(b.Abertura)) : false

        const new_table = virtual_table(t);

        current_table.patch(diff(new_table, current_table), new_table);
    };

    const virtual_table = (data) => (
        create_v_el('table', { class: "table" },
            create_v_el('tr', { class: "table-header" },
                create_v_el('th', { class: "col-11" }, 'Ordem'),
                create_v_el('th', { class: "col-min" }, 'Tempo'),
            ),
            data?.length ?
                _.map(data, (e, i) => {
                    let { d, h, m } = getElapsedTime(dateDiff(e.Abertura));

                    return create_v_el('tr', { class: "table-row " + (priorities[+e.Prioridade - 1] || '') + ((m > 15 || h > 0 || d > 0) ? ' high' : '') },
                        create_v_el('td', { class: "col-11" }, e.Fantasia ? create_v_el(Marquee_v, { text: e.Fantasia }) : `ATENDIMENTO ${i + 1}`.toUpperCase())
                        ,
                        create_v_el('td', { class: "col-min" }, (d > 0 ? d + 'dia(s)' : addZero(h) + ':' + addZero(m))),
                    );
                })
                :
                data && create_v_el('td', { colspan: 2 }, "Nenhum Atendimento Pendente")
        )
    );

    return (current_table = virtual_table(data)).render();
};
