import './styles/main.scss';
import { init_config } from './utils/config.js';
import { match, render } from "./utils/utils.js";
import Home from "./views/home.js";
import Login from "./views/login.js";


const routes = [
    { path: '/login', page: Login },
    { path: '/', page: Home }
];

//window.location.href = window.location.href

window.addEventListener('DOMContentLoaded', () => {
    // o window.location.path, já é pego automaticamente dentro da função match
    const current = match(routes);

    init_config();
    if (!current) render(Home, current);
    else render(current.page, current);
});