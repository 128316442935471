import _ from 'lodash';
import { create_v_el } from '../core/createElement.js';
import { diff } from '../core/diff.js';
import { addZero, childList_observer, dateDiff, getElapsedTime, onInit, run_or_stop_signs } from '../utils/utils.js';
import Marquee_v from './marquee.js';

let current_table;

export default function AnalystTable({ 'is-null': is_null, data, shared = {} }, children) {

    onInit(() => {
        childList_observer(current_table.element, ms => run_or_stop_signs(current_table.element));
    });


    shared.analyst_table_data_updater = (data) => {
        is_null && is_null(!data);

        const t = data ? data.map(e => {
            let { d, h, m } = getElapsedTime(dateDiff(e.date_log));

            e.date_log = addZero(h) + ':' + addZero(m);
            return e;
        }).sort((a, b) => b.date_log.localeCompare(a.date_log))
            : false;

        const new_table = virtual_table(t);

        current_table.patch(diff(new_table, current_table), new_table);
    };

    const virtual_table = (data) => create_v_el('table', { class: "table" },
        create_v_el('tr', { class: "table-header" },
            create_v_el('th', { class: "col-11" }, 'Analista'),
            create_v_el('th', { class: "col-min" }, 'Tempo'),
        ),
        data?.length ?
            _.map(data, e => create_v_el('tr', { class: "table-row " + (e.cliAtendUsuIndisp == 0 ? ' normal' : ' low') },
                create_v_el('td', { class: "col-11" }, create_v_el(Marquee_v, { text: e.cliFantasia })),
                create_v_el('td', { class: "col-min" }, e.date_log),
            ))
            :
            data && create_v_el('td', { colspan: 2 }, 'Nenhum Analista Disponivel')
    );

    return (current_table = virtual_table(data)).render();
};