import { CREATE, isStr, REMOVE, REMOVE_PROP, REPLACE, SET_PROP, TEXT, UPDATE } from "../utils/utils.js";


const do_el = (node) => { return isStr(node) ? document.createTextNode(node) : node.render(); };

const set_prop = (target, name, value) => { target.setAttribute && target.setAttribute(name, value) };

const remove_prop = (target, name) => target.removeAttribute(name)

export function patch(parent, patches, element, index = 0) {
    if (!patches) return;

    const el = element

    switch (patches.type) {

        case CREATE: return parent.appendChild(do_el(patches?.newNode));

        case REMOVE: return parent.removeChild(el);

        case REPLACE: return parent.replaceChild(do_el(patches?.newNode), el);

        case TEXT: {
            if (el.textContent) el.textContent = patches.value
            else if (el.nodeValue) el.nodeValue = patches.value
            return;
        };

        case UPDATE: {
            patchProps(el, patches.props);

            const r_nodes = Array.from(el.childNodes);
            let idx = 0;
            for (var child of patches.children) patch(el, child, r_nodes[idx++]);
        };
    };
};

function patchProps(parent, patches) {
    for (let i = 0; i < patches.length; i++) {
        const { type, name, value } = patches[i];
        if (type === SET_PROP) set_prop(parent, name, value);
        if (type === REMOVE_PROP) remove_prop(parent, name);
    };
};

