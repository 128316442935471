import _ from 'lodash';
import h, { create_v_el } from '../core/createElement.js';
import { diff } from '../core/diff.js';
import { addZero, childList_observer, dateDiff, getElapsedTime, onInit, run_or_stop_signs } from '../utils/utils.js';
import Marquee_v from './marquee.js';


let current_table = null;

export default function OsGoingTable({ 'is-null': is_null, data, shared = {} }, children) {

    onInit(() => {
        childList_observer(current_table.element, ms => run_or_stop_signs(current_table.element));
    });

    shared.os_going_table_data_updater = (data) => {
        is_null && is_null(!data);

        const t = data ? data.sort((a, b) => a.transfDate.localeCompare(b.transfDate)) : false;

        const new_table = virtual_table(t);

        current_table.patch(diff(new_table, current_table), new_table);
    };

    const virtual_table = (data) => create_v_el('table', { class: "table" },
        create_v_el('tr', { class: "table-header" },
            create_v_el('th', { class: "col-7" }, 'Cliente'),
            create_v_el('th', { class: "col-2" }, 'Consultor'),
            create_v_el('th', { class: "col-2" }, 'Analista'),
            create_v_el('th', { class: "col-min" }, 'Tempo'),
        ),
        data?.length ?
            _.map(data, e => {
                const { d, h, m } = getElapsedTime(dateDiff(e.transfDate));
                let c = "table-row ";

                if (m > 30 || h > 0 || d > 0) c += " mt-30";
                if (shared.consult_filter !== 'all' && !shared.consult_filter.includes(String(e.analista_consultor_id))) c += " extone";

                return create_v_el('tr', { class: c },
                    create_v_el('td', { class: "col-7" }, create_v_el(Marquee_v, { text: e.Fantasia })),
                    create_v_el('td', { class: "col-2" }, create_v_el(Marquee_v, { text: e.Consultor })),
                    create_v_el('td', { class: "col-2" }, create_v_el(Marquee_v, { text: e.Analista })),
                    create_v_el('td', { class: "col-min" }, (d > 0 ? d + 'dia(s)' : addZero(h) + ':' + addZero(m))),
                );
            })
            :
            data && create_v_el('td', { colspan: 4 }, 'Nenhum Atendimento Em Andamento')
    );

    return (current_table = virtual_table(data)).render();
};