import h from '../core/createElement.js';

export default function CheckBox(props) {
    const id = props.id || "chk_" + Math.random();

    let long_click_start, long_click_end, diff_start_end, bar_timeout, minimum = 800

    /**@type {HTMLElement} */
    const self_element = (
        <div class={`check-box ${props.class || ''}`}>
            <input id={id} type="checkbox" checked={!!props.default} />
            <label class="chk" for={id}></label>
            <label class="label" for={id} >{props.title || ''}</label>
        </div>
    );

    if (props.longClick) {
        const _mouse_leave = () => {
            self_element.removeEventListener('mouseleave', _mouse_leave);

            clearTimeout(bar_timeout);
            
            self_element.style = Date.now() > minimum ? '' : '--transition: 500ms';
        };

        self_element.addEventListener('mousedown', e => {
            long_click_start = Date.now();

            bar_timeout = setTimeout(() => self_element.style = '--long-click-bar: 100%; --transition: 500ms', 300);

            self_element.addEventListener('mouseleave', _mouse_leave);
        });

        self_element.addEventListener('mouseup', e => {
            long_click_end = Date.now();

            diff_start_end = (long_click_end - long_click_start) + 1;

            _mouse_leave();

            if (diff_start_end > minimum) props.handleLongClick && props.handleLongClick(self_element)
        });
    };

    return self_element;
};
