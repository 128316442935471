import h from '../core/createElement.js';


export default function Input(props = {}, children) {
    props.class ? props.class += " input-box" : props.class = "input-box";
    props.white && (props.class += " white");

    return (
        <div class={props.class}>
            {props?.icon || ''}
            <input required={!!props.required} name={props.name || false} type={props.type || false} />
            <label>{props.title || ''}</label>
        </div>
    );
};