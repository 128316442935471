
function path_to_regex(path, keys) {
    let regex = path.replace(/\/(:?)([^\/?]+)(\??)(?=\/|$)/g, (match, cp, key, optional) => {
        if (cp) keys.push({ name: key });
        return cp ? optional ? '(?:\\/([^\\/]+))?' : '\\/([^\\/]+)' : '\\/' + key;
    });

    regex = regex === '*' ? '(.*)' : (regex === '/' ? '' : regex);

    if (keys.length === 0) keys.push();

    return new RegExp('^' + regex + '(?:\\/(?=$))?$', 'i');
};

function get_query(qrystr) {
    const search = new URLSearchParams(qrystr);
    return [...search.entries()].reduce((ac, el) => {
        const [key, val] = el;
        if (ac.hasOwnProperty(key)) {
            if (Array.isArray(ac[key])) ac[key].push(val);
            else ac[key] = [ac[key], val];

        } else ac[key] = val;
        return ac;
    }, {});
};

function get_params(match, keys) {
    const values = [...match.slice(1)];
    return Object.fromEntries(keys.map((key, i) => [key.name, values[i]]));
};

function match(routes = []) {
    const path = window.location.pathname;

    let route = null;

    for (let m of routes) {
        const keys = [], result = path_to_regex(m.path, keys).exec(path);
        if (!result) continue;
        route = { page: m.page, params: get_params(result, keys), query: get_query(window.location.search) };
        break;
    };

    return route;
};


let to_start_stack = [];

function render(page, ctx) {
    const element = page({ context: ctx });

    document.body.replaceChildren(element);

    for (let init of to_start_stack) init(ctx);
    to_start_stack = [];
};

function onInit(callback) {
    if (!callback || typeof callback !== 'function') return;
    to_start_stack.push(callback);
};

function dateDiff(date) {
    const now = new Date();
    const past = new Date(date);
    const diff = Math.abs(now.getTime() - past.getTime());
    const seconds = Math.ceil(diff / 1000);

    return seconds;
};


function getElapsedTime(lapse) {
    let m, h, d;

    m = Math.floor(lapse / 60);
    h = Math.floor(m / 60);
    d = Math.floor(h / 24);

    m = m % 60;
    h = h % 24;
    /// day, hour, minute
    return { d, h, m };
};

function addZero(x) {
    if (x < 10) {
        x = '0' + x;
    } return x;
};

function run_or_stop_signs(target) {
    const m = (target || document).querySelectorAll('.marquee');

    for (var e of m) {
        const span = e.querySelector('span');
        if (e.offsetWidth < span.offsetWidth) e.classList.add('run')
        else e.classList.remove('run')
    };
}

function childList_observer(target, callback) {
    const observer = new MutationObserver((m) => {
        if (typeof callback === 'function') callback(m)
    });

    observer.observe(target, { childList: true })
};

function width_observer(target, callback) {
    let width;

    const resizeObserver = new ResizeObserver(entries => {
        if (width !== entries[0].target.clientWidth && typeof callback === 'function') {
            width = entries[0].target.clientWidth;
            callback(width);
        };
    });

    resizeObserver.observe(target)
};

const isStr = (str) => typeof str === 'string';

const isObj = (obj) => typeof obj === 'object';

function clock(selector) {
    let target = document.querySelector(selector)
    setInterval(function () {
        let date = new Date(),
            hour = date.getHours(),
            min = date.getMinutes(),
            sec = date.getSeconds();

        min = addZero(min);
        sec = addZero(sec);
        target.textContent = `${hour}:${min}:${sec}`;
    }, 1000)
};

const CREATE = 'CREATE',
    REMOVE = 'REMOVE',
    REPLACE = 'REPLACE',
    UPDATE = 'UPDATE',
    SET_PROP = 'SET_PROP',
    REMOVE_PROP = 'REMOVE PROP',
    TEXT = 'TEXT';

const niveis = ['Analista', 'Monitoria', 'Consultoria', 'Administrativo', 'Analista de Teste', 'Desenvolvimento', 'Outro'];

const switchable_element = (element) => {
    const default_element = element, marker = document.createComment('--s-s--');

    let prev_el = default_element, current_el = default_element;

    const s = (el) => {
        if (current_el.isConnected) current_el.parentNode.replaceChild(el, current_el);
        prev_el = current_el;
        current_el = el;
    };

    const show = (boolean) => {
        if (current_el.isConnected == boolean) return;
        if (boolean) marker.parentNode.replaceChild(current_el, marker);
        else current_el.parentNode.replaceChild(marker, current_el);
    };

    return {
        switch: (el) => { s(el) },
        back_default: () => { s(default_element) },
        hide: (b) => { show(!b) },
        show,
        current: () => current_el
    };
};

const shared_values = () => {
    const shared = {};
    return shared;
}

export {
    match, render, onInit, addZero, clock, getElapsedTime, dateDiff, isObj, childList_observer, isStr,
    width_observer, run_or_stop_signs, CREATE, REMOVE, REPLACE, UPDATE, SET_PROP, REMOVE_PROP, TEXT, niveis,
    switchable_element, shared_values
}