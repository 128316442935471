import Modal from "../../core/modalEngine";
import GlassBox from "../glass_box";
import h from '../../core/createElement';
import Btn from "../button";
import HandredPercentSpinner from "../handred_percent_spinner";
import axios from "axios";

function confirm_delete_preset({ id, hide, shared }) {
    let spinner, msg_div;

    const alert = (text) => msg_div.replaceChildren(text ? h('span', { class: 'alert' }, text) : '');

    const delete_preset = async () => {
        spinner.show();

        await axios.delete('/api/preset/' + id).then(resp => {
            shared?.refresh_consult_painel();
            
            hide();
        }).catch(err => {
            if (err == 'unauthorized') window.location.replace('/login');
            alert('Ocorreu um erro interno, por favor recarrege a pagina.');
        });

        spinner.hide();
    };

    return (
        <GlassBox opacity={0.9}>
            <div class="hide_modal_btn">
                <span onClick={hide}></span>
            </div>
            {msg_div = <div class="alert_modal_preset_section"></div>}
            <section class="top_modal_preset">
                <h3> Deseja realmente excluir este preset? </h3>

                <div class="modal-btns-confirm">
                    <Btn title="Cancelar" color="gray" onClick={hide} />
                    <Btn title="Excluir" color="danger" onClick={delete_preset} />
                </div>
            </section>
            <HandredPercentSpinner control={c => spinner = c} />
        </GlassBox>
    )
};

export default function call_preset_confirm_delete_modal(props) {
    const modal = new Modal(confirm_delete_preset);
    modal.show(props);
};