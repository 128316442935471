import _ from 'lodash';
import h from '../core/createElement';
import Btn from './button';
import CheckBox from './check_box';
import call_pre_set_form_modal from './modals/pre_set_form_modal';
import call_preset_confirm_delete_modal from './modals/confirm_delete_preset';

export default function PresetSelect({ list, shared = {} }) {
    /**@type {HTMLElement} */
    let check_boxes_div = null, id_selected = null;

    const submit_delete = async (element) => {
        const checkbox = element.querySelector('[type="checkbox"]');

        if (!checkbox) return;

        const id = checkbox.id.replace('preset-select-', '');
        call_preset_confirm_delete_modal({id: +id, shared});
    };

    shared.set_preset_selected = (id) => {
        const one = check_boxes_div.querySelector('#preset-select-' + id);
        const checkboxes = check_boxes_div.querySelectorAll('[type="checkbox"]');

        _.forEach(checkboxes, e => { e.checked = false });

        if (one) {
            one.checked = true;

            if(id == id_selected) return;

            const preset = _.find(list, { id: +id });

            if (preset) shared?.set_filter_by_preset(preset);

        } else { shared?.set_painel_title('Consultor') };

        id_selected = id;
    };

    const handle_select = (e) => {
        if (!e.target.matches('[type="checkbox"]')) return;

        shared?.set_preset_selected(e.target.id.replace('preset-select-', ''));

        shared?.set_painel_title('Preset');
    };

    return (
        <div>
            {check_boxes_div =
                <div class="list-container-preset-select" onCLick={handle_select}>
                    {list.map((e, i) => <CheckBox id={'preset-select-' + e.id} class="clean" handleLongClick={submit_delete} longClick={true} title={e.name} />)}
                    {!list.length && <span class="empty_presset">Não Há Presets</span>}
                </div>
            }

            <div class="btn-container-preset-select">
                <Btn color="success">
                    <img src="/image/plus.svg" alt="Novo" onCLick={() => call_pre_set_form_modal({ shared })} />
                </Btn>
            </div>
        </div>
    );
};