import CheckBox from "./check_box";
import h from '../core/createElement';

export default function ConsultSelect({ default_all, list, id_sufix = '', onChange = () => { }, shared = {} }) {
    let div;

    shared.set_filter_by_preset = (preset) => {
        _.forEach([...div.querySelectorAll('[type="checkbox"]')], e => { e.checked = preset.consult_array.includes(+e.id.replace('idx_chk_' + id_sufix, '')) });

        const id_string_array = _.map(preset.consult_array, String);

        shared?.filter_by_consult(id_string_array);
        shared?.filter_counter_update(id_string_array);
        shared?.set_painel_label([preset.name]);
        shared?.set_painel_title('Preset');
    };

    const handle = e => {
        if (e.target.matches('[type="checkbox"]')) {
            const all_grade = div.querySelector('#idx_chk_all');

            const checkboxes = _.filter([...div.querySelectorAll('[type="checkbox"]')], e => e.id.includes('idx_chk_' + id_sufix));

            if (all_grade === e.target) {
                let id;
                if (all_grade.checked) {
                    _.forEach(checkboxes, e => e.checked = true);
                    id = 'all';
                } else {
                    checkboxes.forEach(e => e.checked = false);
                    checkboxes.filter(e => e.id != 'idx_chk_all')[Math.floor(Math.random() * (checkboxes.length - 2))].checked = true;
                };
            };

            const checked = _.chain(checkboxes).filter(e => e.checked && e.id != 'idx_chk_all').map(e => ({ element: e, id: e.id.replace('idx_chk_' + id_sufix, '') })).value();

            if (checked?.length < 1) return e.preventDefault();

            let ids = _.map(checked, 'id');

            if (all_grade && (all_grade.checked = checked?.length >= list.length - 1)) ids = 'all';

            return onChange(ids);
        };
    };

    const handle_long_click = (element) => {
        _.forEach([...div.querySelectorAll('[type="checkbox"]')], e => { e.checked = false });

        const checkbox = element.querySelector('[type="checkbox"]');

        if (!checkbox) return;

        const id = checkbox.id.replace('idx_chk_' + id_sufix, '');

        checkbox.checked = true;

        onChange([id]);
    };

    return div = <div class="row d-flex-column" onClick={(e) => handle(e)}>
        {list.map((e) => <CheckBox default={default_all} handleLongClick={handle_long_click} longClick={e.id != 'all'} id={`idx_chk_${id_sufix || ''}${e.id}`} title={e.nome} />)}
    </div>
}