
const def_config = {
    font_size: { min: 16, max: 23, default: 16 }, // 7 regulagens
    body_size: { min: 1342, max: 1910, default: 1342 }, // 16 regulagens
    outline: { default: false }, // checkbox
    notification: { default: false },
    marquee: { min: 2, max: 12, default: 5 } // 10 regulagen
};





let _config = {
    font_size: { value: def_config.font_size.default, stage: 1 },
    body_size: { value: def_config.body_size.default, stage: 1 },
    outline: { value: def_config.outline.default },
    notification: { value: def_config.notification.default },
    marquee: { value: def_config.marquee.default, stage: 5 }
};

// recebe um booleano
async function change_notification(boolean = false, callback) {
    _config.notification.value = !!boolean;
    await notification_enable_if_suported();
    callback && callback(_config.notification.value);
    apply_config();
};

// recebe de 1 a 8
function change_font_size(num_plus = 0, callback) {
    if (!+num_plus) return;

    const def_conf = def_config.font_size, n = def_conf.min + (+num_plus - 1);

    _config.font_size.value = (n > def_conf.max) ? def_conf.max : (n < def_conf.min ? def_conf.min : n);
    _config.font_size.stage = +num_plus;

    apply_config();
    if (typeof callback === 'function') callback(_config.font_size);
};

// recebe de 1 a 16
function change_body_size(num_plus = 0, callback) {
    if (!+num_plus) return;

    const def_conf = def_config.body_size, n = def_conf.min + (+num_plus - 1) * 35.5;

    _config.body_size.value = (n > def_conf.max) ? def_conf.max : (n < def_conf.min ? def_conf.min : n);
    _config.body_size.stage = +num_plus;

    apply_config();
    if (typeof callback === 'function') callback(_config.body_size);
};

// recebe um booleano
function change_outline(bool = false, callback) {
    _config.outline.value = !!bool;
    apply_config();
    if (typeof callback === 'function') callback(_config.outline);
};

// recebe de 2 a 12
function change_marquee_time(num_abs = 0, callback) {
    if (!+num_abs) return;

    const def_conf = def_config.marquee, n = +num_abs > def_conf.max ? def_conf.max : (+num_abs < def_conf.min ? def_conf.min : +num_abs);

    _config.marquee.value = (n > def_conf.max) ? def_conf.max : (n < def_conf.min ? def_conf.min : n);
    _config.marquee.stage = +num_abs;

    apply_config();
    if (typeof callback === 'function') callback(_config.marquee);
}


const get_local_config = () => {
    let local_obj = localStorage.getItem("config_params");

    if (!local_obj) return _config;

    local_obj = JSON.parse(local_obj);

    return validate_local_config(local_obj);
};

const init_config = async () => {
    get_local_config();
    await notification_enable_if_suported();
    apply_config();
};

const validate_local_config = (local_obj) => {
    if (!local_obj.body_size || !local_obj.font_size || !local_obj.marquee || !local_obj.outline) return _config;

    const verify_values = (v, max, min) => +v > max ? max : (+v < min ? min : +v);

    const { body_size, font_size, marquee } = def_config;

    const config = {
        body_size: { value: verify_values(local_obj.body_size.value, body_size.max, body_size.min), stage: local_obj.body_size.stage },
        font_size: { value: verify_values(local_obj.font_size.value, font_size.max, font_size.min), stage: local_obj.font_size.stage },
        outline: { value: !!local_obj?.outline?.value },
        notification: { value: !!local_obj?.notification?.value },
        marquee: { value: verify_values(local_obj.marquee.value, marquee.max, marquee.min), stage: local_obj.marquee.stage }
    };
    return _config = config;
};


const apply_config = () => {
    const { font_size, body_size, outline, marquee } = _config;

    document.body.style = `
        --fz: ${font_size.value}px;
        --bz-min: ${body_size.value}px;
        --marquee-time: ${marquee.value}s;
        ${outline.value ? '--outline-color: black;--outline-color-secundary: white;' : ''}
    `;

    localStorage.setItem("config_params", JSON.stringify(_config));
};

async function notification_enable_if_suported() {
    if (!_config.notification.value) return;

    switch (Notification.permission) {
        case 'default': return await Notification.requestPermission().then((result) => {
            if (result == 'granted') return _config.notification.value = true;
            _config.notification.value = false;
        });
        case 'granted': return _config.notification.value = true;
        case 'denied':
        default: return _config.notification.value = false;
    };
};

export { get_local_config, change_font_size, change_body_size, change_outline, change_marquee_time, init_config, change_notification }