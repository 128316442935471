import h from "../core/createElement.js";
import { onInit } from "../utils/utils.js";
import axios from "axios";

import Btn from "../modules/button.js";
import Container from "../modules/container.js";
import GlassBox from "../modules/glass_box.js";
import Input from "../modules/input.js";

export default function Login(props, children) {
    document.title = 'Max Painel - login';

    let login_el, password_el, alert_el, info_el,
        btn_loading, form_container;

    onInit(() => {
        login_el = document.querySelector('[name="login"]');
        password_el = document.querySelector('[name="password"]');
        form_container = document.querySelector('.form-container');
    });


    const do_login = async (e) => {
        e.preventDefault();
        alert_el.innerText = '';

        const login = login_el.value, password = password_el.value;

        if (!login || !password) return alert_el.innerText = "Por favor preencha todos os campos!";

        btn_loading.show();

        await axios.post('/api/login', { login, password }).then(resp => {
            const data = resp.data;

            btn_loading.hide();

            if (data.status === 'success') return redirect();
        }).catch(err => {
            btn_loading.hide();
            return alert_el.innerText = err.response.data.msg || '';
        })
    };

    const redirect = () => {
        form_container.classList.add('slide-up-desappear')
        form_container.addEventListener('animationend', () => {
            form_container.remove();
            window.location.replace('/');
        })
    };

    //container
    return (
        <Container class="h-flex-center">
            <GlassBox class="grid-full-center fade_in_scale_apper form-container">
                <div class="row v-flex-center d-flex-column">
                    <img src="image/Logo-title.png" alt="Logo" class="form-logo" />
                    {info_el = <span class="info"></span>}
                    {alert_el = <span class="alert"></span>}
                </div>

                <form class="login-form" onSubmit={(e) => do_login(e)} >

                    <Input title="Login" white={true} required={true} name="login" type="text" />
                    <Input title="Senha" white={true} required={true} name="password" type="password" />

                    <div class="d-flex h-flex-center">
                        <Btn title="Entrar" color="success" loading={l => btn_loading = l} />
                    </div>
                </form>
            </GlassBox>
        </Container>
    );
}