import h from '../core/createElement';

export default function HandredPercentSpinner({ control }) {
    /** @type {HTMLElement} */
    const spinner = (
        <div class="hundred-percent-spinner" style="display:none">
            <span class="spinner"></span>
        </div>
    );

    control && control({
        hide: () => spinner.style.display = 'none',
        show: () => spinner.style.display = 'flex'
    })

    return spinner
}