import _ from "lodash";
import h from "../core/createElement.js"
import DropDown from "./dropdown"
import HighPainel from "./high_painel.js";
import VerticalMarquee from "./vertical_marquee.js";
import ConsultSelect from "./consult_select.js";
import PresetSelect from "./preset_select.js";

export default function ConsultHighPainel({ shared = {} }, children) {

    const { user_data } = shared;

    const list = [{ nome: 'TODOS', id: 'all' }, ...user_data.consultores] || [{ nome: 'TODOS', id: 'all' }], ref = user_data.consultor_ref;

    const get_name_by_id = (ids) => _.chain(list).filter(e => ids.includes(String(e.id))).map('nome').value();

    shared.filter_counter_update = (ids) => {
        if (ids == 'all' || ids.length == 1) return filter_count.style.display = 'none';
        filter_count.style.display = '';
        filter_count.innerText = ids.length;
    };

    shared.set_painel_title = (title = '') => {
        const title_el = document.querySelector('#consult-dropdown-title');
        if (title_el.innerText === title) return;
        title_el.innerText = title;
    };

    let filter_count;

    const consultor_default = list.find(e => e.id == ref) || { nome: 'TODOS', id: 'all' };

    const handle_change = (id_arr) => {
        shared?.filter_counter_update(id_arr);
        shared?.set_painel_label(get_name_by_id(id_arr));
        shared?.set_painel_title(id_arr.length > 1 ? 'Consultores' : 'Consultor');
        shared?.set_preset_selected();
        shared?.filter_by_consult(id_arr);
    };

    return (
        <HighPainel title="Consultor" id-title="consult-dropdown-title" id-label="consultor-nome" label={<VerticalMarquee shared={shared} itens={[consultor_default.nome]} />} >
            {list.length > 2 &&
                <section class="btns-container">
                    {(user_data.nivel >= 3 || shared?.plantao()) &&
                        <DropDown btn={<img src="/image/preset.svg" />} color="gray">
                            <PresetSelect list={user_data.presets} consult_list={list} shared={shared} />
                        </DropDown>
                    }

                    <DropDown btn={<img src="/image/filter.svg" />} color="zen">
                        <ConsultSelect list={list} onChange={handle_change} default_all={true} shared={shared} />
                    </DropDown>

                    {filter_count = <span style="display: none" class="filter-count"></span>}
                </section>
            }
        </HighPainel>

    )
};