
import _ from 'lodash';
import axios from 'axios';
import { io } from 'socket.io-client';

import Btn from '../modules/button.js';
import h from '../core/createElement.js';
import GlassBox from '../modules/glass_box.js';
import Container from '../modules/container.js';
import HighPainel from '../modules/high_painel.js';
import { get_local_config } from '../utils/config.js';
import AnalystTable from '../modules/analyst_table.js';
import ConfigPainel from '../modules/config_painel.js';
import OsGoingTable from '../modules/os_going_table.js';
import OsPendingTable from '../modules/os_pending_table.js';
import ConsultHighPainel from '../modules/consult_dropdown.js';
import DropDown, { drop_down_out_click_dissmiss } from '../modules/dropdown.js';
import { clock, onInit, run_or_stop_signs, shared_values, switchable_element, width_observer } from '../utils/utils.js';

export default function Home(props, children) {

    const spinner_black = () => (<div class="row h-flex-center"> <span class="spinner black"></span> </div>),
        reload_consult_painel = () => consultor_sign.switch(<ConsultHighPainel shared={shared} />),
        reload_config_painel = () => {
            usuario_sign.switch(
                <GlassBox class="d-flex v-flex-center">
                    {/* <Btn color="gray" ><img src="/image/code.svg" /></Btn> */}

                    <DropDown btn={<img src="/image/gear.svg" />} color="zen" left={true}>
                        <ConfigPainel />
                    </DropDown>

                    <Btn title="Sair" color="danger" onClick={e => logOut()} />
                </GlassBox>
            );
        };

    const consultor_sign = switchable_element(<HighPainel title="" label="" />),
        usuario_sign = switchable_element(<HighPainel title="" label="" />),
        pending_so_loading = switchable_element(spinner_black()),
        going_so_loading = switchable_element(spinner_black()),
        analyst_loading = switchable_element(spinner_black());


    const shared = shared_values();

    let connection, is_focused_window = true;

    shared.consult_filter = "all"

    onInit(async () => {
        clock('#clock');

        width_observer(document.body, w => run_or_stop_signs());

        drop_down_out_click_dissmiss();

        window.addEventListener('focus', () => { is_focused_window = true; });
        window.addEventListener('blur', () => { is_focused_window = false; });

        await axios.get('/api/userinfo').then(resp => {
            const response = resp.data;

            if (response.status !== 'success') return;

            shared.user_data = response.data;

            if (shared.user_data.consultor_ref && (shared.user_data.consultores?.length ?? 0) == 1) shared.consult_filter = [String(shared.user_data.consultor_ref)]

            reload_config_painel();

            init_socket();

            fetch_presets_if_needed();

        }).catch(err => {
            if (err?.response?.status === 401) window.location.replace('/login');
        });
    });

    const fetch_presets_if_needed = async () => {
        if (shared.user_data.consultor_ref === null || shared?.plantao()) {

            await axios.get('/api/preset').then(resp => {
                const response = resp?.data;

                if (response.status !== 'success') return;

                shared.user_data.presets = response.data;
            }).catch(err => { });

        };
        reload_consult_painel();
    };

    const init_socket = () => {
        connection = io();

        connection.on('connect', () => { });

        connection.on('connect-error', (err) => {
            if (err == 'unauthorized') window.location.replace('/login');
        });

        connection.on('data', (d) => {
            update_lists(d);
        });

        connection.on('notification', (notification = []) => {
            const config = get_local_config();

            if (config.notification.value && !is_focused_window) {
                if (!_.isArray(notification) && !(shared.consult_filter == 'all' || _.find(notification, e => shared.consult_filter.includes(String(e.consultor_id))))) return;

                new Notification('Novo Atendimento!', {
                    body: 'Há um novo atendimento pendente!',
                    icon: '/image/favicon.ico',
                    badge: '/image/favicon.ico'
                }).addEventListener('click', (e) => {
                    e.preventDefault();
                    window.focus();
                });
            };
        });
    };

    const logOut = async () => {
        axios.post('/api/logout').then(resp => {
            window.location.replace('/login');
        }).catch(err => { });
    };

    const filter_object = (obj) => {
        if (!obj) return false;
        if (shared.consult_filter == 'all' || ((shared.user_data.consultores?.length ?? 0) == 1) && shared.user_data.consultor_ref) return obj;
        return _.filter(obj, e => (shared.consult_filter.includes(String(e.consultor_id)) || shared.consult_filter.includes(String(e.analista_consultor_id))));
    };

    const update_lists = ({ os_em_atendimento, analistas_disponiveis, pendente } = {}) => {
        shared?.os_going_table_data_updater(filter_object(os_em_atendimento));
        shared?.analyst_table_data_updater(filter_object(analistas_disponiveis));
        shared?.os_pending_table_data_updater(filter_object(pendente));
    };

    shared.refresh_consult_painel = () => {
        consultor_sign.back_default();
        fetch_presets_if_needed();
        shared?.filter_by_consult('all');
    };

    shared.filter_by_consult = (filter) => {
        update_lists();
        shared.consult_filter = filter;
    };

    shared.plantao = () => {
        const { user_data } = shared;
        return user_data.id == 999 && user_data.consultor_ref == null;
    };

    return (
        <Container class="d-flex-column">

            <header class="row d-flex h-space-between">

                <div class="col-3 data-board">
                    {consultor_sign.current()}
                </div>

                <div class="col-3 data-board d-flex h-flex-center">
                    <HighPainel title="Hora" id-label="clock" label="00:00:00" />
                </div>

                <div class="col-3 data-board d-flex v-flex-center h-flex-center">
                    {usuario_sign.current()}
                </div>

            </header>

            <section class="row">

                <GlassBox class="col-3 data-board slide-bottom-fade-appear first" opacity="0.6">
                    <div class="row h-flex-center">
                        <span class="board-title">Atendimentos Pendentes</span>
                    </div>

                    <div class="row subtitles">
                        <label><span class="low"></span> Baixo</label>
                        <label><span class="normal"></span> Normal</label>
                        <label><span class="high"></span> Urgente</label>
                        <label><span class="awaiting"></span> Retorno</label>
                        <label><span class="missout"></span> Não atende</label>
                    </div>

                    <div class="table-container" id="pending_so">
                        <OsPendingTable data={false} shared={shared} is-null={b => pending_so_loading.show(b)} />
                    </div>

                    {pending_so_loading.current()}
                </GlassBox>

                <GlassBox class="col-6 data-board slide-bottom-fade-appear second" opacity="0.6">
                    <div class="row h-flex-center">
                        <span class="board-title">Atendimentos Em Andamento</span>
                    </div>

                    <div class="row subtitles">
                        <label><span class="mt-30"></span> Mais de 30 minutos</label>
                        <label><span class="extone"></span> Atendido por outra equipe</label>
                    </div>

                    <div class="table-container" id="going_so">
                        <OsGoingTable shared={shared} is-null={b => going_so_loading.show(b)} data={false} />
                    </div>

                    {going_so_loading.current()}
                </GlassBox>

                <GlassBox class="col-3 data-board slide-bottom-fade-appear third" opacity="0.6">
                    <div class="row h-flex-center">
                        <span class="board-title">Analistas Disponiveis</span>
                    </div>

                    <div class="row subtitles">
                        <label><span class="normal"></span> Disponivel</label>
                        <label><span class="low"></span> Indisponivel</label>
                    </div>

                    <div class="table-container" id="analyst">
                        <AnalystTable data={false} shared={shared} is-null={b => analyst_loading.show(b)} />
                    </div>

                    {analyst_loading.current()}
                </GlassBox>

            </section>

        </Container>
    );
};