import h from '../core/createElement.js';
import { change_body_size, change_font_size, change_marquee_time, change_notification, change_outline, get_local_config } from '../utils/config.js';
import { addZero } from '../utils/utils.js';



export default function ConfigPainel(props, children) {
    let font_span, body_span, marquee_span;

    const config = get_local_config();

    const change_font = (e) => change_font_size(e.target.value, r => font_span.innerHTML = `Fonte: ${r.value}`);

    const change_body = (e) => change_body_size(e.target.value, r => body_span.innerHTML = `Largura Minima: ${r.value}`);

    const change_marquee = (e) => change_marquee_time(e.target.value, r => marquee_span.innerHTML = `Tempo Do Letreiro: ${addZero(r.value)}s`);

    return (
        <div class="row d-flex-column">
            <label class="range">
                {font_span = <span class="range-title">Fonte: {config.font_size.value}</span>}
                <input type="range" step="1" min="1" max="8" value={config.font_size.stage} onChange={e => change_font(e)} />
            </label>

            <label class="range">
                {body_span = <span class="range-title">Largura Minima: {config.body_size.value}</span>}
                <input type="range" step="1" min="1" max="17" value={config.body_size.stage} onChange={e => change_body(e)} />
            </label>

            <label class="range">
                {marquee_span = <span class="range-title">Tempo Do Letreiro: {addZero(config.marquee.value)}s</span>}
                <input type="range" min="2" max="12" value={config.marquee.stage} onChange={e => change_marquee(e)} />
            </label>

            <div class="toggle-btn">
                <span>Notificações: </span>
                <input type="checkbox" id="notification-chk" checked={config.notification.value} onChange={e => change_notification(e.target.checked, t => e.target.checked = t)} />
                <label for="notification-chk"></label>
            </div>

            <div class="toggle-btn">
                <span>Contorno: </span>
                <input type="checkbox" id="outline-chk" checked={config.outline.value} onChange={e => change_outline(e.target.checked)} />
                <label for="outline-chk"></label>
            </div>
        </div>
    );
};