import { isObj } from "../utils/utils.js";
import { patch } from "./patch.js";

export default function h(tag, props = {}, ...children) {
    if (typeof tag === 'function') return tag(props || {}, children);

    const element = document.createElement(tag);

    for (let [name, value] of Object.entries(props || {})) {
        if (name.startsWith("on") && name.toLowerCase() in window) value && element.addEventListener(name.toLowerCase().substring(2), value);
        else if (typeof value === 'boolean') value && element.setAttribute(name, '');
        else element.setAttribute(name, value && value.toString());
    };

    for (let child of children) { appendChild(element, child); };

    return element;
};

function appendChild(parent, child) {
    if (Array.isArray(child)) for (let nChild of child) appendChild(parent, nChild);
    else if (child) parent.appendChild(child.nodeType ? child : document.createTextNode(child));
};


export function create_v_el(tag, props = {}, ...children) {
    if (typeof tag === 'function') return tag(props || {}, children);
    return new Element(tag, props, ...children);
};


class Element {
    constructor(tag, props = {}, ...children) {
        this.type = tag;
        this.props = props || {};
        this.children = children.flat().filter(el => el);
        this.element;
    };

    render() {
        this.element = document.createElement(this.type)
        let props = this.props, children = this.children || [];

        for (let [name, value] of Object.entries(props || {})) {
            if (name.startsWith("on") && name.toLowerCase() in window) value && this.element.addEventListener(name.toLowerCase().substring(2), value);
            else if (typeof value === 'boolean') value && this.element.setAttribute(name, '');
            else this.element.setAttribute(name, value && value.toString());
        };

        for (let child of children) {
            var childEl = isObj(child) ? child.render() : document.createTextNode(child);
            this.element.appendChild(childEl);
        };

        this.element._owner_ = this;
        return this.element;
    };

    patch(patches, newobj) {
        if (this.element) {
            patch(this.element.parentNode, patches, this.element);
            Object.assign(this, newobj);
        };
    };
};