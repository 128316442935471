import h from "./createElement";



export default class Modal {
    component = null;
    current = null;

    constructor(Component) {
        if (!Component) throw new Error('O contrutor do modal precisa da referência de um componente');
        this.component = Component;
    };

    show(props) {
        const wrapper = inject_wrapper();

        const overlay_n_child = h('section', { class: 'overlay' }, h(this.component, { ...props, hide: () => this.hide() }));

        wrapper.appendChild(overlay_n_child);

        this.current = overlay_n_child;
    };

    hide() {
        if (this.current && this.current.isConnected) this.current.remove();
    };
};


function inject_wrapper() {
    const main_section = document.querySelector('section.modal-wrapper') || h('section', { class: 'modal-wrapper' });
    if (!main_section.isConnected) document.body.appendChild(main_section);
    return main_section
};