import h from '../core/createElement.js';

export default function VerticalMarquee({ itens = [], shared = {} }) {

    let animation_interval, idx = 0;

    const marquee = (
        <div class="vertical_marquee">
            <div>
                {itens.map(e => <h2>{e}</h2>)}
            </div>
        </div>
    );

    shared.set_painel_label = (new_itens) => {
        itens = new_itens;

        marquee.replaceChildren(
            <div>
                {itens.map(e => <h2>{e}</h2>)}
            </div>
        );

        init_animation();
    };

    const init_animation = () => {
        clearInterval(animation_interval);
        marquee.style = '--marquee-step:0';
        idx = 0;

        if (itens.length <= 1) return;

        animation_interval = setInterval(() => {
            marquee.style = `--marquee-step: -${idx * 32}px`;
            idx++;
            if (idx >= itens.length) idx = 0;
        }, 2500);
    };

    return marquee;
};