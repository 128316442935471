import h from "../core/createElement.js";
import GlassBox from "./glass_box.js";

export default function HighPainel(props = {}, children) {

    return (
        <GlassBox class="d-flex v-flex-center h-padding-6 h-space-between" opacity="0.8">
            <div class="high-card">
                <span id={props['id-title']}>{props.title || '...'}</span>
                <h2 id={props['id-label'] || false}>{props.label || '...'}</h2>
            </div>

            {(!props.title && !props['id-label']) && <span class="spinner black" style="--size:14px;--thickness:3px"></span>}

            {children}
        </GlassBox>
    )
};