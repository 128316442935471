import Modal from "../../core/modalEngine";
import GlassBox from "../glass_box";
import h from '../../core/createElement'
import Input from "../input";
import ConsultSelect from "../consult_select";
import Btn from "../button";
import axios from "axios";
import HandredPercentSpinner from "../handred_percent_spinner";

function pre_set_modal({ hide, shared = {} }) {
    /** @type {HTMLElement} */
    let msg_div = null,
        /** @type {HTMLElement} */
        input_pack = null,
        consult_arr = [],
        spinner;

    const consult_list = shared.user_data.consultores;

    const alert = (text) => msg_div.replaceChildren(text ? h('span', { class: 'alert' }, text) : '');

    const submit = async () => {
        const name_preset = input_pack?.querySelector('input').value;

        if (!name_preset) return alert('O nome do preset é obrigatório!');
        if (name_preset.length < 3 || name_preset.length > 20) return alert('O nome deve ter entre 3 e 20 caracteres');
        if (!consult_arr.length) return alert('Não há consultores selecionados!');
        if (consult_arr.length === 1) return alert('Selecione pelo menos 2 consultores!');

        alert('');

        spinner.show();

        await axios.post('/api/preset', { name: name_preset, consult_array: _.map(consult_arr, e => +e) }).then(resp => {
            
            shared?.refresh_consult_painel();
            
            hide();

        }).catch(err => {
            if (err.response?.data) {
                if (err.response?.data?.msgs) return alert(err.response?.data.msgs[0]?.msg);
                if (err.response?.data?.msg) return alert(err.response?.data?.msg);
            };
            if (err == 'unauthorized') window.location.replace('/login');
        });

        spinner.hide();
    };

    return (
        <GlassBox opacity={0.9}>
            <div class="hide_modal_btn">
                <span onClick={hide}></span>
            </div>

            {msg_div = <div class="alert_modal_preset_section"></div>}
            
            <section class="modal_preset_content">
                <section class="top_modal_preset">
                    {input_pack = <Input title="Nome do preset" name="preset_name" />}

                    <article class="modal_preset_about">
                        <h2>Sobre os presets</h2>
                        <p>
                            <ul>
                                <li>Não podem ser editados</li>
                                <li>São linkados ao perfil que os criou</li>
                                <li>Apenas para nivel Administrativo ou acima</li>
                                <li>Apenas 20 por usuário</li>
                            </ul>
                        </p>
                    </article>

                    <Btn title="Salvar" color="success" onClick={submit} />
                </section>

                <section class="bottom_modal_preset">
                    <ConsultSelect list={consult_list} id_sufix={'preset'} onChange={a => consult_arr = a} default_all={false} />
                </section>
            </section>
            <HandredPercentSpinner control={c => spinner = c} />
        </GlassBox>
    )
};


export default function call_pre_set_form_modal(props) {
    const modal = new Modal(pre_set_modal);
    modal.show(props);
};